/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'camera-video-off-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10.961 12.365a2 2 0 00.522-1.103l3.11 1.382A1 1 0 0016 11.731V4.269a1 1 0 00-1.406-.913l-3.111 1.382A2 2 0 009.5 3H4.272zm-10.114-9A2 2 0 000 5v6a2 2 0 002 2h5.728zm9.746 11.925l-10-14 .814-.58 10 14z"/>',
    },
});
